export const FILTER_CLIENT_KEY = 'filter_client'
export const VEHICULES_LIST_FILTERS_KEY = 'VEHICULES_LIST_FILTERS'
export const DESTOCKAGE_LIST_FILTERS_KEY = 'DESTOCKAGE_LIST_FILTERS'

export class StorageManager {
  static setFilter(filterKey, filterValue) {
    if (filterValue === null) {
      sessionStorage.removeItem(filterKey)
    } else {
      sessionStorage.setItem(filterKey, JSON.stringify(filterValue))
    }
  }

  static getFilter(filterKey) {
    return sessionStorage.getItem(filterKey) !== null ? JSON.parse(sessionStorage.getItem(filterKey)) : null
  }

  static hasFilter(filterKey) {
    return sessionStorage.getItem(filterKey) !== null
  }
}
