export default class FileManager {
  static forceFileDownload(fileName, mimeType, body) {
    const uri = `data:${mimeType};base64,${body}`
    const link = document.createElement('a')
    link.href = uri
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  }
}
