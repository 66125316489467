import { ref, watch, computed } from '@vue/composition-api'

export default function useRemoteList() {
  const selectedClientId = ref(null)
  const refListTable = ref(null)

  // Table Handlers

  const perPage = ref(50)
  const total = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('siteProvenanceNom')
  const isSortDirDesc = ref(false)

  const sortDirection = computed(() => (isSortDirDesc.value ? 'Desc' : 'Asc'))
  const sortColumn = computed(() => {
    switch (sortBy.value) {
      case 'siteProvenanceNom': return 'SiteProvenanceId'
      case 'siteDestinationNom': return 'SiteDestinationId'
      case 'modeleNom': return 'ModeleId'
      default: return sortBy.value.charAt(0).toUpperCase() + sortBy.value.slice(1)
    }
  })

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: total.value,
    }
  })

  const refetchData = () => {
    if (refListTable.value) {
      refListTable.value.refresh()
    }
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  return {
    perPage,
    currentPage,
    total,
    dataMeta,
    perPageOptions,
    sortBy,
    sortColumn,
    isSortDirDesc,
    sortDirection,
    refListTable,
    refetchData,

    selectedClientId,
  }
}
