<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <label>Client : </label>
          <v-select
            v-if="clientsOptions.length"
            :value="selectedClientId"
            :options="clientsOptions"
            :reduce="client => client.id"
            label="nom"
            class="w-100 select-cursor"
            :clearable="false"
            @input="(val) => $emit('update:selectedClientId', val)"
          ><div slot="no-options">
            Aucune option
          </div></v-select>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import sortByName from '@/utils/SortListByName'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
  },
  props: {
    selectedClientId: {
      type: [Number, null],
      default: null,
    },
    canClientBeNullable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clientsOptions: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      store.dispatch('client/fetchClients')
        .then(response => {
          if (!this.canClientBeNullable) {
            this.clientsOptions = sortByName(response.data)
            if (this.selectedClientId === null && this.clientsOptions.length > 0) {
              this.$emit('update:selectedClientId', this.clientsOptions[0].id)
            }
          } else {
            this.clientsOptions = [{ id: null, nom: 'Tous' }, ...response.data]
          }
        })
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
